<template>
  <div>
    <!-- -------万恶分割线------- -->
    <div style="margin-bottom: 20px">
      <el-row>
        <el-col :span="24" align="left">
          <el-button type="primary" @click="goback">返回</el-button>
        </el-col>
      </el-row>
    </div>

    <div class="main">
      <el-form ref="perForm" :model="formData"  :rules="rules"   label-width="120px">
        <el-form-item label="文件信息" prop="fileList"> </el-form-item>
        <el-row v-for="(item, index) of formData.fileList" :key="index">
          <el-col>
            <el-form-item
              label="上传类型"
              v-model="item.fileType"
              :prop="'fileList.' + index + '.fileType'"
              :rules="rules.fileType"
            >
              <el-radio-group v-model="item.fileType">
                <el-radio label="1">图片</el-radio>
                <el-radio label="2">视频</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item
              label="上传图片"
              v-model="item.fid"
              v-if="item.fileType == 1"
              :prop="'fileList.' + index + '.fid'"
              :rules="rules.fid"
            >
              <el-upload
                class="avatar-uploader"
                action="http://up-z0.qiniup.com"
                :show-file-list="false"
                accept="image/jpeg,image/gif,image/png"
                :on-success="
                  (res) => {
                    return handleAvatarSuccess(res, index)
                  }
                "
                :data="postData"
                :before-upload="beforeAvatarUpload"
              >
                <div v-if="item.fid" style="width: 300px">
                  <img
                    :src="`http://file.innopinenut.com/${item.fid}`"
                    width="50%"
                  />
                </div>

                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>

          <el-col>
            <el-form-item
              label="上传视频"
              v-model="item.fid"
              v-if="item.fileType == 2"
              :prop="'fileList.' + index + '.fid'"
              :rules="rules.fid"
            >
              <el-upload
                class="upload-demo"
                action="http://up-z0.qiniup.com"
                :auto-upload="true"
                :show-file-list="true"
                accept=".mp4,.MP4"
                :file-list="fileList"
                :limit="1"
                :on-success="
                  (res) => {
                    return handleAvatarSuccess0(res, index)
                  }
                "
                :data="postData0"
                :before-upload="beforeAvatarUpload0"
              >
                <el-button type="primary">点击上传</el-button>
                <template #tip>
                  <div class="el-upload__tip">只能上传mp4类型的文件</div>
                </template>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item
              label="封面"
              v-model="item.posterId"
              v-if="item.fileType == 2"
            >
              <el-upload
                class="avatar-uploader"
                action="http://up-z0.qiniup.com"
                :show-file-list="false"
                accept="image/jpeg,image/gif,image/png"
                :on-success="
                  (res) => {
                    return handleAvatarSuccess1(res, index)
                  }
                "
                :data="postData1"
                :before-upload="beforeAvatarUpload1"
              >
                <div v-if="item.posterId" style="width: 300px">
                  <img
                    :src="`http://file.innopinenut.com/${item.posterId}`"
                    width="50%"
                  />
                </div>

                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-button
            size="mini"
            type="text"
            style="border-radius: 2px; color: #b93947"
            @click="handleAdd(index)"
            v-if="formData.fileList.length == index + 1"
          >
            <i class="el-icon-circle-plus-outline"></i>&nbsp;添加文件</el-button
          >
          <el-button
            style="margin-top: 2px"
            v-if="formData.fileList.length != 1"
            type="text"
            class="deleteBox"
            @click="handleDel(index)"
          >
            <i class="el-icon-delete"></i>&nbsp;删除</el-button
          >
        </el-row>
      </el-form>
      <div style="margin-bottom: 20px">
        <el-row>
          <el-col :span="24" align="center">
            <el-button type="primary" @click="btnOK">提 交</el-button>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
  
<script>
import {
  addAlbumFile,
  getAlbumFileList,
  delAlbumFile,
  getUploadToken
} from '../../request/http'
export default {
  data() {
    return {
      url: 'http://file.innopinenut.com/',
      editOradd: null,
      imgList: [], //多张照片回显
      fileList: [],
      imageUrl: '',
      img: '',
      dialogVisible: false,
      postData: {
        token: '',
        key: '' //上传的Logo
      },

      postData0: {
        token: '',
        key: ''
      },
      index: 0,
      postData1: {
        token: '',
        key: '' //上传的Logo
      },
      albumId: '',
      formData: {
        albumId: '',

        fileList: [
          {
            fileType: null,
            fid: '',
            posterId: ''
          }
        ]
      },

      rules: {
        fileType: [
          { required: true, message: '上传类型不能为空', trigger: 'blur' }
        ],
        fid: [{ required: true, message: '文件不能为空', trigger: 'blur' }]
      }
    }
  },
  created() {
    this.albumId = this.$route.query.albumId
    this.formData.albumId = this.$route.query.albumId
    this.editOradd = this.$route.query.editOradd == 0 ? false : true
  },
  methods: {
    handleAdd(index) {
      this.index = index + 1
      console.log(this.index, '添加')

      console.log(this.formData)
      this.formData.fileList.push({
        fileType: null,
        fid: '',
        posterId: ''
      })
    },
    handleDel(index) {
      this.index = index
      console.log(this.index, '删除')

      console.log(this.formData)
      this.formData.fileList.splice(index, 1)
    },

    // 返回上一级页面
    goback() {
      this.$router.go(-1)
    },

    // handlePreview(file) {
    //   if (this.formData.fileType == 2 && this.formData.fid) {
    //     window.open(
    //       'http://file.innopinenut.com/' + this.formData.fid,
    //       '_blank'
    //     )
    //   } else {
    //     this.$message.warning('暂不支持预览')
    //   }
    // },
    // 上传图片方法
    handleAvatarSuccess(res, index) {
      
        
    //   console.log(this.index, '图片')
    //   console.log('上传成功', res, index)

    //   this.formData.fileList[index].fid = res.key
    },
    async beforeAvatarUpload(file) {
      let { data } = await getUploadToken()
      if (data == '' || data == null) {
        this.message.error('上传时获取Token失败！')
        return
      }
      this.postData.token = data.token
      this.postData.key = data.fid
    },
    // 上传方法
    handleAvatarSuccess0(res, index) {
      console.log(this.index, '视频')
      console.log('上传成功', res, index)
      this.formData.fileList[index].fid = res.key
    },
    async beforeAvatarUpload0(file) {
      let { data } = await getUploadToken()
      if (data == '' || data == null) {
        this.message.error('上传文件时获取Token失败！')
        return
      }
      this.postData0.token = data.token
      this.postData0.key = data.fid
    },

    // 上传图片方法
    handleAvatarSuccess1(res, index) {
      console.log(this.index, '封面')
      console.log('上传成功', res, index)

      this.formData.fileList[index].posterId = res.key
      console.log(this.formData.fileList)
    },
    async beforeAvatarUpload1(file) {
      // console.log("上传前");
      let { data } = await getUploadToken()
      // console.log(data);
      if (data == '' || data == null) {
        this.message.error('上传时获取Token失败！')
        return
      }
      this.postData1.token = data.token
      this.postData1.key = data.fid
    },

    //提交
    async btnOK() {
      this.$refs.perForm.validate().then(async () => {
        console.log(this.formData)
        this.formData.fileList = this.formData.fileList.filter((v) => {   // v是变量 固定写法 newArr是需要过滤的数组
             return (
               Object.values(v).filter((i) => {
                 return i!=null;
               }).length === Object.values(v).length
             );
           });
        let { code, msg } = await addAlbumFile(this.formData)
        console.log(code, msg)
        if (code == 0) {
          this.$message.success('新增成功')
        } else {
          console.log(msg)
          this.$message({
            type: 'error',
            message: msg
          })
        }
        this.goback()
      })
    }
  }
}
</script>

<style>
</style>